import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { fabric } from 'fabric';
import ImagePaletteBox from './ImagePaletteBox';
import "./ProductColor.css";

const ImagePaletteComponent = ({ initialPaletteColors, canvas, setSingleColor, setIsPng, removeBackground, calculatePrice, handleColorReset, addToHistory, saveCanvasDataToLocalStorage, activeTab,dominantColors  }) => {
  const [paletteColors, setPaletteColors] = useState(initialPaletteColors || []);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentColor, setCurrentColor] = useState(null);
  const [dominantColor, setDominantColor] = useState(null);


  
  console.log("dominantcolorss",dominantColors);
 
  useEffect(() => {
    setPaletteColors(initialPaletteColors);
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.dominant_color) {
      setDominantColor(activeObject.dominant_color);
    
    }
  }, [initialPaletteColors, canvas]);

  useEffect(() => {
    setPaletteColors(initialPaletteColors);
  }, [initialPaletteColors]);

  console.log(dominantColor);

  const handleColorClick = (color, index) => {
    setCurrentColor(color);
    setCurrentIndex(index);
    setShowColorPicker(true);
  };
  

  function loadImageFromUrl(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = function () {
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        var data = imageData.data;
        var colorMap = {};
        var maxCount = 0;
        var dominantColor = [0, 0, 0];

        for (var i = 0; i < data.length; i += 4) {
          var color = [data[i], data[i + 1], data[i + 2]];
          var key = color.join(",");
          colorMap[key] = (colorMap[key] || 0) + 1;
          if (colorMap[key] > maxCount) {
            maxCount = colorMap[key];
            dominantColor = color;
          }
        }
        var rgb = "rgb(" + dominantColor.join(",") + ")";
        resolve(rgb);
      };
      img.onerror = function (error) {
        reject(error);
      };
      img.src = url;
    });
  }
 
const removeBgColor = async (activeObject, value) => {
    try {
      if (activeObject && activeObject.type === "image") {
        if (value) {
          const imgSrc = activeObject.getSrc();
          const dominantColor = await loadImageFromUrl(imgSrc);

          const removeColorFilter = new fabric.Image.filters.RemoveColor({
            distance: 0.2,
            color: dominantColor,
            alpha: 1,
          });
          activeObject.filters.push(removeColorFilter);
          activeObject.applyFilters();
          activeObject.set({
            bgRemove: true,
          });
        } else {
          const filters = activeObject.filters;
          if (filters && filters.length > 0) {
            for (let i = filters.length - 1; i >= 0; i--) {
              if (filters[i] instanceof fabric.Image.filters.RemoveColor) {
                filters.splice(i, 1); // Remove the color filter
              }
            }
          }
          activeObject.applyFilters();
          activeObject.set({
            bgRemove: false,
          });
        }
        canvas.renderAll();
      }
    } catch (error) {
      console.error("Error in removeBgColor:", error);
    }
  };
  // useEffect(() => {
  //   if (canvas) {
  //     const constrainObjectToDesignArea = (obj) => {
  //       const designArea = canvas.getObjects().find(o => o.designArea);
  //       if (designArea) {
  //         const { left: designAreaLeft, top: designAreaTop, width: designAreaWidth, height: designAreaHeight } = designArea;
  
  //         if (obj.left < designAreaLeft) {
  //           obj.set('left', designAreaLeft);
  //         }
  //         if (obj.top < designAreaTop) {
  //           obj.set('top', designAreaTop);
  //         }
  //         if (obj.left + obj.getScaledWidth() > designAreaLeft + designAreaWidth) {
  //           obj.set('left', designAreaLeft + designAreaWidth - obj.getScaledWidth());
  //         }
  //         if (obj.top + obj.getScaledHeight() > designAreaTop + designAreaHeight) {
  //           obj.set('top', designAreaTop + designAreaHeight - obj.getScaledHeight());
  //         }
  //         obj.setCoords();
  //       }
  //     };
  
  //     const updateObjectPercentages = (obj) => {
  //       const designArea = canvas.getObjects().find(o => o.designArea);
  //       if (designArea) {
  //         const { left: designAreaLeft, top: designAreaTop, width: designAreaWidth, height: designAreaHeight } = designArea;
  
  //         const leftPercent = ((obj.left - designAreaLeft) / designAreaWidth) * 100;
  //         const topPercent = ((obj.top - designAreaTop) / designAreaHeight) * 100;
  //         const widthPercent = (obj.getScaledWidth() / designAreaWidth) * 100;
  //         const heightPercent = (obj.getScaledHeight() / designAreaHeight) * 100;
  
  //         obj.set({
  //           leftPercent: leftPercent,
  //           topPercent: topPercent,
  //           widthPercent: widthPercent,
  //           heightPercent: heightPercent,
  //         });
  //       }
  //     };
  
  //     canvas.on('object:moving', (e) => {
  //       const obj = e.target;
  //       constrainObjectToDesignArea(obj);
  //       updateObjectPercentages(obj);
  //     });
  
  //     canvas.on('object:scaling', (e) => {
  //       const obj = e.target;
  //       constrainObjectToDesignArea(obj);
  //       updateObjectPercentages(obj);
  //     });
  
  //     const handleResize = () => {
  //       const designArea = canvas.getObjects().find(o => o.designArea);
  
  //       if (canvas && designArea) {
  //         const { left: designAreaLeft, top: designAreaTop, width: designAreaWidth, height: designAreaHeight } = designArea;
  
  //         canvas.getObjects().forEach((obj) => {
  //           if (obj.designElement) {
  //             const objLeftPercent = obj.leftPercent;
  //             const objTopPercent = obj.topPercent;
  //             const objWidthPercent = obj.widthPercent;
  //             const objHeightPercent = obj.heightPercent;
  
  //             const newLeft = designAreaLeft + (designAreaWidth * objLeftPercent) / 100;
  //             const newTop = designAreaTop + (designAreaHeight * objTopPercent) / 100;
  //             const newWidth = (designAreaWidth * objWidthPercent) / 100;
  //             const newHeight = (designAreaHeight * objHeightPercent) / 100;
  
  //             obj.set({
  //               left: newLeft,
  //               top: newTop,
  //               scaleX: newWidth / obj.width,
  //               scaleY: newHeight / obj.height,
  //             });
  //             constrainObjectToDesignArea(obj);
  //             obj.setCoords();
  //           }
  //         });
  
  //         canvas.renderAll();
  //       }
  //     };
  
  //     window.addEventListener('resize', handleResize);
  
  //     return () => {
  //       canvas.off('object:moving');
  //       canvas.off('object:scaling');
  //       window.removeEventListener('resize', handleResize);
  //     };
  //   }
  // }, [canvas]);

const handleNewColorChange = async (newColor) => {
  try {
    const activeObject = canvas.getActiveObject();
    if (!activeObject) {
      console.log('No active object on canvas.');
      return;
    }

    // Save the current state for undo history
    const prevState = activeObject.toObject(['left', 'top', 'scaleX', 'scaleY', 'src', 'paletteColors']);

    const oldColor = paletteColors[currentIndex];
    if (!oldColor) {
      console.log('Old color not found in paletteColors at index:', currentIndex);
      return;
    }

    const oldColorHex = [oldColor[2], oldColor[1], oldColor[0]];
    const newColorHex = [newColor.r, newColor.g, newColor.b];
    const response = await axios.post('https://colors.inkox.com/api/change-color', {
      oldColor: oldColorHex,
      newColor: newColorHex,
      session_id: activeObject.session_id,
    });

    if (response.status === 200) {
      const newImagePath = "https://colors.inkox.com/" + response.data.newImagePath;
      const base64 = await loadImageToBase64(newImagePath);
      console.log(base64);

      if (activeObject && activeObject.type === "image") {
        fabric.Image.fromURL(base64, (img) => {
          img.colorChanged = true;

          img.set({
            src: newImagePath,
            top: activeObject.top,
            flipX: activeObject.flipX,
            flipY: activeObject.flipY,
            id: activeObject.id,
            bgRemove: activeObject.bgRemove,
            filters: activeObject.filters,
            img: true,
            origX: activeObject.origX,
            origY: activeObject.origY,
            left: activeObject.left,
            dominant_color: activeObject.dominant_color,
            makeOneColorCode: activeObject.makeOneColorCode,
            makeSingleColor: activeObject.makeSingleColor,
            makeSingleColorInvert: activeObject.makeSingleColorInvert,
            makeOneColor: activeObject.makeOneColor,
            paletteColors: activeObject.paletteColors,
            angle: activeObject.angle,
            session_id: activeObject.session_id,
            scaleX: activeObject.scaleX,
            scaleY: activeObject.scaleY,
            lock: activeObject.lock,
            zoomX: activeObject.zoomX,
            opacity: activeObject.opacity,
            zoomY: activeObject.zoomY,
            quantized_image: activeObject.quantized_image,
            type: "image",
            imageFile: activeObject.imageFile,
            imageType: activeObject.imageType,
            newImagePath: newImagePath,
            singleColor: activeObject.singleColor,
            extractedColorVal: activeObject.extractedColorVal,
            selectedColorIndex: activeObject.selectedColorIndex,
            size: 0.8,
            cache: true,
            designElement: activeObject.designElement,
            imageSmoothingEnabled: true,
            imageSmoothingQuality: "high",

          });

          if (img.bgRemove) {
            removeBgColor(img, true);
          }

          // Update the colors in the palette
          const updatedColors = [...paletteColors];
          updatedColors[currentIndex] = [newColor.b, newColor.g, newColor.r];
          img.paletteColors = updatedColors;
          setPaletteColors(updatedColors);

          // Replace the old object with the new one on the canvas
          canvas.remove(activeObject);
          canvas.add(img);
          canvas.setActiveObject(img);
          canvas.renderAll();
          calculatePrice(canvas); // Calculate price after adding the image


          img.setControlVisible("ml", false);
          img.setControlVisible("mb", false);
          img.setControlVisible("mr", false);
          img.setControlVisible("mt", false);

          // Save the new state for undo history
          // const newState = img.toObject(['left', 'top', 'scaleX', 'scaleY', 'src', 'paletteColors']);
          
          // Consolidate the history entry
          // addToHistory(img, prevState, newState, 'colorChange'); 

          saveCanvasDataToLocalStorage(activeTab);
        });
      }

      setShowColorPicker(false);
    }
  } catch (error) {
    console.error('Error changing color:', error);
  }
};

  
  

  const handleCloseColorPicker = () => {
    setShowColorPicker(false);
  };

  const loadImageToBase64 = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const base64 = canvas.toDataURL("image/png");
        resolve(base64);
      };
      img.onerror = function (error) {
        reject(error);
      };
      img.src = url;
    });
  };
  console.log("dominantColor",dominantColor);
  console.log("removeBackground",removeBackground);


  return (
    <div className="image-palette">
      <div className="palette-colors outline-main">
        {paletteColors && paletteColors.map((color, index) => (
          <>          <div >
          <div
            key={index}
            className="color-box"
            style={{marginBottom:'0px', backgroundColor: `rgb(${color[2]}, ${color[1]}, ${color[0]})` }}
            onClick={() => handleColorClick(color, index)}
          >
            {removeBackground && dominantColors && 
              dominantColors[0] === color[2] &&
              dominantColors[1] === color[1] &&
              dominantColors[2] === color[0] && (
              <span className="cross-icon">&#10060;</span>
            )}

          </div>
          </div>
          </>

        ))}
      </div>
      {showColorPicker && (
        <ImagePaletteBox
          onColorChange={handleNewColorChange}
          onClose={handleCloseColorPicker}
          currentColor={currentColor}
          setSingleColor={setSingleColor}
          handleColorReset={handleColorReset}
        />
      )}
    </div>
  );
};

export default ImagePaletteComponent;
