import React, { useState, useEffect } from "react";
import slugify from "slugify";
import productFilterImage from "../assets/images/shirt3.png"; // Placeholder image
import LayoutWrapper from "../layout/layoutWrapper/LayoutWrapper";
import Faqs from "../widgets/faqs/Faqs";
import ProductsWithFilters from "../widgets/productsWithFilters/ProductsWithFilters";
import TipOfTheDay from "../widgets/tipOfTheDay/TipOfTheDay";
import { useParams } from "react-router-dom";

const Loader = () => (
  <div className="spinner"></div>
);

const ProductCategory = () => {
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [selectedColor, setSelectedColor] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedFit, setSelectedFit] = useState(null);
  const [selectedWeight, setSelectedWeight] = useState(null);
  const [loadingProducts, setLoadingProducts] = useState(true); // Loading state for products
  const [currentImages, setCurrentImages] = useState({});
  const [selectedColors, setSelectedColors] = useState({});
  const [subCategoryName, setSubCategoryName] = useState(""); // State for subcategory name
  const [loading, setLoading] = useState(true); // Global loading state


console.log("selectedColors:",selectedColors);

  // Fetch products based on subcategory id from the URL


  
  useEffect(() => {
    setLoadingProducts(true); // Start loading

    fetch(`https://backend.inkox.com/api/product-subcategories-all/${id}`)
      .then((response) => response.json())
      .then((data) => {
        const productsArray = data.map(item => item.products); // Extract products from each object
        setProducts(productsArray);

                // Initialize current images with default images
                const initialColors = {};
                productsArray.forEach((product) => {
                  const subcat =
                    product?.nestedProperty?.subcat || product.subcat;
        
                  if (subcat) {
                    const productImages =
                      typeof subcat === "string"
                        ? JSON.parse(subcat)
                        : subcat;
                    const firstColor = Object.keys(productImages)[0];
                    initialColors[product.id] = firstColor || null;
                  }
                });
        
                setSelectedColors(initialColors);
        setLoadingProducts(false); // Stop loading

      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
        setLoadingProducts(false); // Stop loading on error

      });
  }, [id]);

   // Fetch data (products and subcategory name) when `id` changes
   useEffect(() => {
    setLoading(true);

    // Use Promise.all to fetch products and subcategory concurrently
    Promise.all([
      fetch(`https://backend.inkox.com/api/product-subcategories-all/${id}`).then((res) => res.json()),
      fetch("https://backend.inkox.com/api/product-sub-categories").then((res) => res.json()),
    ])
      .then(([productData, subCategoryData]) => {
        // Process products
        const productsArray = productData.flatMap((item) => item.products || []);
        setProducts(productsArray);

        // Get subcategory name
        const selectedSubCategory = subCategoryData.find(
          (subCategory) => subCategory.id === parseInt(id, 10)
        );
        setSubCategoryName(selectedSubCategory?.name || "Products");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setSubCategoryName("Products"); // Fallback name
      })
      .finally(() => setLoading(false)); // Stop loading
  }, [id]);

  // Fetch sizes
  useEffect(() => {

    fetch("https://backend.inkox.com/api/sizes")
      .then((response) => response.json())
      .then((data) => {
        setSizes(data);

      })
      .catch((error) => {
        console.error("Error fetching colors data:", error);
      });
  }, []);

  // Fetch colors
  useEffect(() => {

    fetch("https://backend.inkox.com/api/colors")
      .then((response) => response.json())
      .then((data) => {
        setColors(data);

      })
      .catch((error) => console.error("Error fetching colors data:", error));
  }, []);


 

  const tipOfTheDay = {
    title: "<span class='text-red'>Tip</span> of the <span class='text-skyblue'>Day</span>",
    description:
      "Page yield is how many pages your printer cartridge can produce before it needs replacement. To calculate the Paper cost, here's another math task:",
  };

  // Helper function to find size by id
  const findSizeById = (sizeId) => sizes.find((size) => size.id === sizeId)?.size || "N/A";

  const handleColorSelect = (productId, colorKey) => {
    console.log("productId &&& colorKey:",productId,colorKey);
    setSelectedColors((prev) => ({
      ...prev,
      [productId]: colorKey, // Update selected color for the specific product
    }));
  };

  // Filter products based on the selected filters
  const filteredProducts = products.filter((product) => {
    // Handle color filtering
    const matchesColor =
      selectedColor.length === 0 ||
      selectedColor.some((color) => product.productColor.includes(color));

    // Handle size filtering
    const matchesSize =
      !selectedSize || product.productSize?.includes(selectedSize);

    // Handle material filtering
    const matchesMaterial = !selectedMaterial || product.material === selectedMaterial;

    // Handle brand filtering
    const matchesBrand = !selectedBrand || product.brand === selectedBrand;

    // Handle fit filtering
    const matchesFit = !selectedFit || product.Fit === selectedFit;

    // Handle weight filtering
    const matchesWeight = !selectedWeight || product.Weight === selectedWeight;

    return (
      matchesColor &&
      matchesSize &&
      matchesMaterial &&
      matchesBrand &&
      matchesFit &&
      matchesWeight
    );
  });

 

  const productsWithFilters = filteredProducts.map((product) => {
    // Parse productColor as an object if it's a valid JSON string
    let productColors = [];
    try {
      productColors = Object.keys(JSON.parse(product.productColor)) || [];
    } catch (e) {
      productColors = [];
    }

    // Parse productSize if it's a valid JSON string, else default to "N/A"
    let productSize = "N/A";
    try {
      productSize = Array.isArray(JSON.parse(product.productSize)) 
        ? JSON.parse(product.productSize).join(", ") 
        : "N/A";
    } catch (e) {
      productSize = "N/A";
    }

    const subcat = product?.nestedProperty?.subcat || product.subcat;

    let currentImage = productFilterImage; // Default to placeholder image

    try {
      const productImages = subcat
        ? typeof subcat === "string"
          ? JSON.parse(subcat)
          : subcat
        : null;
    
      // Normalize the selected color key
      const normalizeKey = (key) =>
        key
          .toLowerCase() // Convert to lowercase
          .replace(/[^a-z0-9]/g, ""); // Remove special characters (keep alphanumeric only)
    
      const selectedColor = selectedColors[product.id]
        ? normalizeKey(selectedColors[product.id])
        : null;
    
      console.log("Normalized Selected Color:", selectedColor); // Debug normalized selected color
      console.log("Product Images Object:", productImages); // Debug product images
    
      if (selectedColor && productImages) {
        // Normalize the productImages keys
        const normalizedImages = Object.fromEntries(
          Object.entries(productImages).map(([key, value]) => [normalizeKey(key), value])
        );
    
        // Check for "snscanada" vendor case
        if (product.vendor === "snscanada" && normalizedImages[selectedColor]?.front) {
          currentImage = `https://backend.inkox.com/${normalizedImages[selectedColor].front}`;
        } 
        // Default case for other vendors
        else if (normalizedImages[selectedColor]) {
          currentImage = `https://backend.inkox.com/${normalizedImages[selectedColor]}`;
        } else {
          console.warn("No valid image found for normalized color:", selectedColor);
        }
      }
    } catch (error) {
      console.error("Error parsing subcat:", error);
    }
    
    return {
      id: product.id,
      image: `${currentImage}`,
      topSeller: product.topSeller || false,
      title: product.name || "Unnamed Product",
      link: `${product.name}/${product.id}`,
      size: productSize,
      colors: productColors,
      rating: product.rating || "N/A",
      reviews: product.reviews || "0",
      price: product.price || "No Minimum",
      material: product.material || "Unknown",
      brand: product.brand || "Unknown",
      fit: product.Fit || "Unknown",
      weight: product.Weight || "Unknown",
    };
  });

  return (
    <LayoutWrapper>
        {(loadingProducts) ? (
        <Loader />
      ) : (
        <>
      <ProductsWithFilters subCategoryName={subCategoryName} data={productsWithFilters} handleColorClick={handleColorSelect}  // Pass subcategory name
      />
      <Faqs />
      <TipOfTheDay bgColor={"#fff"} data={tipOfTheDay} />
      </>
           )}
    </LayoutWrapper>
  );
};

export default ProductCategory;
