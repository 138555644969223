import React from "react";
import LayoutWrapper from "../layout/layoutWrapper/LayoutWrapper";
import Cart from "../widgets/cart/Cart";
import TipOfTheDay from "../widgets/tipOfTheDay/TipOfTheDay";

const CartDetail = () => {
  const tipOfTheDay = {

    title:
      "<span class='text-red'>Tip</span> of the <span class='text-skyblue'>Day</span>",
    description:
      "Page yield is how many pages your printer cartridge can produce before it needs replacement. To calculate the Paper cost, here's another math task:",
  };
  return (
   
      <Cart />
     
  
  );
};

export default CartDetail;
