import React, { useEffect, useState } from 'react';
import './ProductColor.css'; // Import CSS file for styling
import close from "../assets/close.png"


const OutlineSettings = ({
  outlineWidth,
  onOutlineWidthChange,
  onOutlineColorChange,
  onClose,
  outlineColor,
}) => {
  const [localOutlineWidth, setLocalOutlineWidth] = useState(outlineWidth);
  const [preDefinedColors, setPreDefinedColors] = useState([]);
  const [selectedColor, setSelectedColor] = useState(outlineColor);
  const [selectedColorName, setSelectedColorName] = useState(''); // State for selected color name
  const [hoveredColorName, setHoveredColorName] = useState(''); // State for hovered color name

  useEffect(() => {
    // Fetch colors from the new API and transform them into the required format
    fetch('https://backend.inkox.com/api/inkcolors')
      .then((response) => response.json())
      .then((data) => {
        // Transform the data to match the expected 'inkColors' array format
        const colors = data.flatMap((item) =>
          item.color_hex.split(',').map((color) => color.trim())
        );
        setPreDefinedColors(colors);
      })
      .catch((error) => {
        console.error('Error fetching the color data:', error);
      });
  }, []);

  const getColorNameFromHex = (color) => {
    // Utility function to fetch the color name from hex
    return fetch('https://backend.inkox.com/api/inkcolors')
      .then((response) => response.json())
      .then((data) => {
        const colorData = data.find((item) =>
          item.color_hex.split(',').map((hex) => hex.trim()).includes(color)
        );
        if (colorData) {
          const colorIndex = colorData.color_hex.split(',').map((hex) => hex.trim()).indexOf(color);
          const colorNames = colorData.color_name.split(',').map((name) => name.trim());
          return colorNames[colorIndex] || '';
        }
        return '';
      });
  };

  const handleOutlineColorChange = (color) => {
    setSelectedColor(color);
    onOutlineColorChange(color);

    // Get color name dynamically and update state
    getColorNameFromHex(color).then(setSelectedColorName);

    if (color === 'none') {
      // If "None" is selected, set outline width to 0
      handleOutlineWidthChange(0);
    }
  };

  const handleOutlineWidthChange = (width) => {
    setLocalOutlineWidth(width);
    onOutlineWidthChange(width);
  };

  const handleHoverColor = (color) => {
    // Update hovered color name dynamically
    getColorNameFromHex(color).then(setHoveredColorName);
  };

  const handleHoverOut = () => {
    // Revert to selected color name when hover is removed
    setHoveredColorName('');
  };

  return (
    <div className="outline-settings">
     <div className="addcliparttwo"


            
style={{
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "3px",
  backgroundColor: "#f6f6f6",
  
}}
>
<h5          // <-- Call the close function on click

  style={{
    margin: "0 auto",
    color: "#211e1eaa",
    fontWeight: "400",
    textAlign: "center",
    flexGrow: 1,
  }}
>
  Edit Outline
</h5>
<button
  onClick={onClose}
  style={{
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    fontSize: "18px",
    fontWeight: "bold",
    marginLeft: "auto",
    color: "#aaaa",
    fontWeight: "300",
  }}
  aria-label="Close" 
>
                          <img src={close} alt="Close" className="closeicon"/>

</button>
</div>
      
      <div className="inner">
        <label>Outline Width:</label>
      </div>
      <div className="inner">
        <input
          className="range-bar"
          type="range"
          min="0"
          max="10"
          value={localOutlineWidth}
          onChange={(e) => handleOutlineWidthChange(parseInt(e.target.value))}
          disabled={selectedColor === 'none'} // Disable width slider if 'None' is selected
        />
      </div>
      <div className="inner">
        <label>Select Outline Color:</label>
      </div>
      {selectedColorName && (
        <div className="inner">
          <b className="color-preview">
            Selected Color: {hoveredColorName || selectedColorName}
          </b>
        </div>
      )}
      <div className="inner">
        <div className="color-grid">
          {/* Predefined Colors */}
          {preDefinedColors.map((color) => (
            <div
              key={color}
              className={`color-box ${color === selectedColor ? 'selected' : ''}`}
              style={{ backgroundColor: color }}
              onClick={() => handleOutlineColorChange(color)}
              onMouseEnter={() => handleHoverColor(color)} // Handle hover
              onMouseLeave={handleHoverOut} // Handle hover out
            />
          ))}
        </div>
      </div>
      <div className="inner">
        <button
          className={`donebutton none-button ${selectedColor === 'none' ? 'selected' : ''}`}
          onClick={() => handleOutlineColorChange('none')}
        >
          None
        </button>
        <button onClick={onClose} className="donebutton">
          Done
        </button>
      </div>
 
    </div>
  );
};

export default OutlineSettings;
