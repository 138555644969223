import React from "react";
import productCategoryImage from "../assets/images/shirt2.png";
import LayoutWrapper from "../layout/layoutWrapper/LayoutWrapper";
import Faqs from "../widgets/faqs/Faqs";
import ProductsWithCategories from "../widgets/productsWithCategories/ProductsWithCategories";
import TipOfTheDay from "../widgets/tipOfTheDay/TipOfTheDay";

const Products = () => {
  const tipOfTheDay = {
    title:
      "<span class='text-red'>Tip</span> of the <span class='text-skyblue'>Day</span>",
    description:
      "Page yield is how many pages your printer cartridge can produce before it needs replacement. To calculate the Paper cost, here's another math task:",
  };

  const productsWithCategories = [
    {
      image: productCategoryImage,
      title: "T-Shirts",
      link: "t-shirt",
    },
    {
      image: productCategoryImage,
      title: "Sweatshirts & Hoodies",
      link: "sweatshirts-hoodies",
    },
    {
      image: productCategoryImage,
      title: "HATS",
      link: "hats/round-hats",
    },
    {
      image: productCategoryImage,
      title: "T-Shirts",
      link: "t-shirt",
    },
    {
      image: productCategoryImage,
      title: "Sweatshirts & Hoodies",
      link: "sweatshirts-hoodies",
    },
    {
      image: productCategoryImage,
      title: "HATS",
      link: "hats",
    },
  ];

  return (
    <LayoutWrapper>
      <ProductsWithCategories data={productsWithCategories} />
      <Faqs />
      <TipOfTheDay bgColor={"#fff"} data={tipOfTheDay} />
    </LayoutWrapper>
  );
};

export default Products;
