import { Col, Row, InputNumber, Input } from "antd";
import React, { useEffect, useState } from "react";
import Title from "../../components/typography/title/Title";
import { Rating } from "react-simple-star-rating";
import { Link, useParams } from "react-router-dom";
import Container from "../../components/container/Container";
import ProductSlider from "../../components/productSlider/ProductSlider";
import { Modal, Button } from 'antd';
import Accordion from "../../components/accordion/Accordion";
import Description from "../../components/typography/description/Description";
import standardImg from "../../assets/images/standard-fit.png";
import heavyWeightImg from "../../assets/images/heavy-weight.png";
import materialImg from "../../assets/images/material.png";
import budgetImg from "../../assets/images/budget.png";
import dollarIcon from "../../assets/images/dollar.png";
import gildanimg from "../../assets/images/35_fm.png";

import free from "../../assets/images/free.png";
import rush from "../../assets/images/rush.png";
import email from "../../assets/images/email.png";

import "./style.css";
import colorName from 'color-name';
import { colorHexMap } from "../../view/colorlibrarygarments";
import { decode } from "html-entities"; // Install with `npm install html-entities`





// Helper function to convert color name to hex
const colorNameToHex = (color) => {
  const colorData = colorHexMap[color];
  return colorData ? colorData.hex : '#000000'; // Default to black if not found
};

// Example usage
const color = "Safety Green";
console.log(colorNameToHex(color));

const ProductDetail = ({ setRProduct }) => {
  const { id } = useParams(); // Get product ID from the URL
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSizes, setSelectedSizes] = useState([]); // To store sizes for selected color
  const [images, setImages] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [frontColor, setFrontColor] = useState(0); // For front design colors
  const [backColor, setBackColor] = useState(0); // For back design colors
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isQuoteVisible, setIsQuoteVisible] = useState(false); // Quote modal visibility

  const [loadingProduct, setLoadingProduct] = useState(true); // Loading state for product
  const [loadingColors, setLoadingColors] = useState(true);  // Loading state for colors
  const [filteredimages, setFilteredImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");


  const Loader = () => (
    <div className="loader-container">
      <div className="spinner"></div>
    </div>
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingProduct(true);
        const productResponse = await fetch(`https://backend.inkox.com/api/product/${id}`);
        const productData = await productResponse.json();
        setProduct(productData);
  
        // Parse productColor
        const colorData =
          typeof productData.productColor === "string"
            ? JSON.parse(productData.productColor)
            : productData.productColor;
  
        const firstColor = Object.keys(colorData)[0];
        setSelectedColor(firstColor); // Set the first color as default
        setSelectedSizes(colorData[firstColor]);
  
        const colorThumbnails =
          typeof productData.productImages2 === "string"
            ? JSON.parse(productData.productImages2)
            : productData.productImages2;
  
        // Normalize keys in `colorThumbnails`
        const normalizedThumbnails = Object.keys(colorThumbnails).reduce((acc, key) => {
          const normalizedKey = key.toLowerCase().replace(/[^a-z0-9]/g, ""); // Normalize to lowercase and remove special characters
          acc[normalizedKey] = colorThumbnails[key];
          return acc;
        }, {});
  
        setImages(normalizedThumbnails); // Save normalized thumbnails
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setLoadingProduct(false);
      }
    };
  
    fetchData();
  }, [id]);

  const calculateDeliveryDate = (workingDays) => {
    const today = new Date();
    let daysAdded = 0;
  
    while (daysAdded < workingDays) {
      today.setDate(today.getDate() + 1);
      // Skip weekends (Saturday: 6, Sunday: 0)
      if (today.getDay() !== 0 && today.getDay() !== 6) {
        daysAdded++;
      }
    }
  
    return today.toDateString(); // Return formatted date
  };
  
  
  useEffect(() => {
    if (selectedColor && images) {
      // Normalize selectedColor by converting to lowercase and removing spaces and slashes
      const normalizedSelectedColor = selectedColor.toLowerCase().replace(/\s+/g, "").replace(/\//g, "").replace(/\^/g, "").replace(/\**/g, "").replace(/\*/g, "");
  
      // Normalize the images keys
      const normalizedImages = Object.keys(images).reduce((acc, key) => {
        const normalizedKey = key.toLowerCase().replace(/\s+/g, "").replace(/\//g, ""); // Remove spaces and slashes
        acc[normalizedKey] = images[key];
        return acc;
      }, {});
  
      // Find matching thumbnails using the normalized color
      const selectedColorThumbnails = normalizedImages[normalizedSelectedColor];
  
      if (selectedColorThumbnails) {
        // Map over image paths and construct valid URLs, keeping `/` intact
        const imageUrls = Object.values(selectedColorThumbnails).map(
          (imagePath) => `https://backend.inkox.com/${imagePath}`
        );
  
        setSelectedImage(imageUrls[0]); // Set the first image as the default
        setFilteredImages(imageUrls); // Set all filtered images for the selected color
      } else {
        setSelectedImage(null); // Reset selected image if no thumbnails exist
        setFilteredImages([]); // Reset filtered images array
      }
    }
  }, [selectedColor, images]);
  

  
  
 

  const findColorByImage = (image) => {
    const productColors = JSON.parse(product.productColor);
    for (const color of Object.keys(productColors)) {
      const formattedColor = color.toLowerCase().replace(/\s+/g, "-");
      if (image.toLowerCase().includes(formattedColor)) {
        return color;
      }
    }
    return null; // Return null if no matching color is found
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    
    // Find and select the color based on the clicked image
    const color = findColorByImage(image);
    if (color) {
      setSelectedColor(color); // Set the found color as the selected color
      setSelectedSizes(JSON.parse(product.productColor)[color]); // Update sizes
    
    }
  };

  const handleColorClick = (color) => {
    setSelectedColor(color);
  
    const productColors = JSON.parse(product.productColor);
    setSelectedSizes(productColors[color]); // Update sizes for the selected color
  
    // Normalize the clicked color for consistent lookup
    const formattedColor = color.toLowerCase().replace(/\s+/g, "").replace(/\//g, "").replace(/\^/g, "").replace(/\*/g, "").replace(/\**/g, ""); // Remove `^` symbol
    ;
  
    // Direct lookup in normalized images
    const selectedColorThumbnails = images[formattedColor];
  
    if (selectedColorThumbnails) {
      const imageUrls = Object.values(selectedColorThumbnails).map(
        (imagePath) => `https://backend.inkox.com/${imagePath}`
      );
  
      setSelectedImage(imageUrls[0]); // Set the first image as the default
      setFilteredImages(imageUrls); // Set all images for the selected color
    } else {
      setSelectedImage(null); // Reset if no images are found
      setFilteredImages([]);
    }
  };
  
  
  console.log('description',product)
  
  const parseDescription = (description) => {
    if (!description) return [];
  
    // Step 1: Decode HTML entities
    const decodedDescription = decode(description);
  
    // Step 2: Replace `<br>` tags with newline characters and remove all other HTML tags
    const cleanText = decodedDescription
      .replace(/<br\s*\/?>/g, '\n') // Convert `<br>` to newline
      .replace(/<\/?[^>]+(>|$)/g, ''); // Remove other HTML tags
  
    // Step 3: Split the text into lines by newline characters
    const lines = cleanText
      .split('\n')
      .map((line) => line.trim()) // Trim whitespace from each line
      .filter((line) => line); // Remove empty lines
  
    // Step 4: Return each line as a list item
    return (
      <ul>
        {lines.map((line, index) => (
          <li key={index} style={{ marginBottom: '10px', lineHeight: '1.5' }}>
            {line}
          </li>
        ))}
      </ul>
    );
  };
  
  
  
  const accordions = [
    {
      title: "Product Description",
      description: product?.description
        ? parseDescription(product.description) // Clean and parse description
        : "No description available",
    },
    {
      title: "Extra Details",
      description: product?.extraDetails || "No extra details available",
    },
    {
      title: "Shipping Options",
      description: product?.shippingOptions || "No shipping options available",
    },
  ];
  
  // Helper function to parse and format description

  
  
  const calculatePrice = () => {
    if (!product) return;

    const numberOfFrontColors = frontColor;
    const numberOfBackColors = backColor;

    let additionalFrontPrice = 0;
    let additionalBackPrice = 0;

    // Calculate additional price for front colors
    if (numberOfFrontColors > 0) {
      additionalFrontPrice += 3; // $3 for the first color
      if (numberOfFrontColors > 1) {
        additionalFrontPrice += (numberOfFrontColors - 1) * 1; // $1 for each additional color
      }
    }

    // Calculate additional price for back colors
    if (numberOfBackColors > 0) {
      additionalBackPrice += 3; // $3 for the first color
      if (numberOfBackColors > 1) {
        additionalBackPrice += (numberOfBackColors - 1) * 1; // $1 for each additional color
      }
    }

    // Convert base price to number if it's a string
    const basePrice = parseFloat(product.price) || 0;

    // Calculate the total price: base price + front color price + back color price, then multiply by quantity
    let newTotalPrice = (basePrice + additionalFrontPrice + additionalBackPrice) * quantity;

    // Round to two decimal places for currency
    newTotalPrice = newTotalPrice.toFixed(2);

    // Update the total price state
    setTotalPrice(newTotalPrice);
  };

  // Update total price whenever quantity, front color, or back color changes
  useEffect(() => {
    if (product) {
      calculatePrice();
    }
  }, [product, quantity, frontColor, backColor]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showQuote = () => {
    setIsQuoteVisible(true);
  };

  const handleClose = () => {
    setIsQuoteVisible(false);
  };



  if (!product) {
    return  <Loader />;
  }

  
  
  const productColors = JSON.parse(product.productColor); // Convert productColor JSON string to an object
  const availableSizes = selectedSizes.length ? selectedSizes : [];

  const colorHex = colorNameToHex(selectedColor);
const encodedColorHex = colorHex.replace('#', '%');
const colorId = colorHexMap[selectedColor]?.id || '';
  return (
    <div className="productDetail">
      <div className="product-overview">
        <Container>
          <Row gutter={[40, 20]}>
            <Col md={9}>
          
  <ProductSlider
                data={filteredimages}
                selectedImage={selectedImage}
                onImageClick={handleImageClick}
                selectedColor={selectedColor}
              />            </Col>
            <Col md={13}>
              <div className="item-detail">
              <img
  src={`https://backend.inkox.com/storage/SNS_Canada_Images_Webp/Brand_Webp/${product.brand || "default"}.webp`} 
  alt={product.brand || "Brand"}
  className="top-icon-product-pge"
/>
              
             
                <Title size={"33"} tag={"h4"} weight={600}>
                  {product.name}
                </Title>
                <div className="reviewsContainer">
                  <Rating emptyColor="#D1CECE" size={22} readonly initialValue={product.rating || 0} />
                  <Title size={"14"} tag={"h6"} weight={600}>
                    {product.reviews || 0} reviews
                  </Title>
                  <a className="readReiewBtn underline text-skyblue1" href={"#review"}>
                    read reviews
                  </a>
                </div>

                {/* Colors Section */}
                <div className="size-container color">
                  <Title size={"18"} tag={"h6"} weight={600}>
                    Colors:
                  </Title>
                  <div className="colors">
                    {Object.keys(productColors).map((color, i) => (
                      <div
                        key={i}
                        className={`color-box ${selectedColor === color ? "selected" : ""}`}
                        style={{
                          backgroundColor: colorNameToHex(color),
                          width: "30px",
                          height: "30px",
                          border: selectedColor === color ? "2px solid #000" : "1px solid #ccc",
                          margin: "5px",
                        }}
                        onClick={() => handleColorClick(color)}
                      ></div>
                    ))}
                  </div>
                </div>

                {/* Sizes Section */}
                <div className="size-container sizes-product-page">
                  <Title size={"18"} tag={"h6"} weight={600}>
                    Sizes Available:
                  </Title>
                  <div className="sizes">
                    <ul>
                      {availableSizes.length > 0 ? (
                        availableSizes.map((size, i) => (
                          <li key={i}>
                            <Title size={"22"} tag={"h6"} weight={400}>
                              {size}
                            </Title>
                          </li>
                        ))
                      ) : (
                        <p>No sizes available for selected color</p>
                      )}
                    </ul>
                  </div>
                </div>

                {/* <div className="links-text">
                  <Title size={"18"} tag={"h6"} weight={500}>
                    Fit: {product.Fit || "N/A"}
                  </Title>
                  <Title size={"18"} tag={"h6"} weight={500}>
                    Material: {product.material || "N/A"}
                  </Title>
                  <Title size={"18"} tag={"h6"} weight={500}>
                    Price: ${totalPrice}
                  </Title>
                </div> */}

             

 <Link
  className="design-now-btn"

  onClick={() => {
    window.top.location.href = `/designer?id=${product.productCanvasId}&pid=${id}&colorId=${colorId}`;
  }}
>
  <Title size={"18"} tag={"h6"} weight={700}>
  Start Designing
  </Title>
</Link>

{/* <Link className="design-now-btn" to={`/designer?id=${product.productCanvasId}&pid=${id}&colorId=${colorId}`}>
  <Title size={"18"} tag={"h6"} weight={700}>
    Design Now
  </Title>
</Link> */}
<div>
                  <p>
                    Standard fit straight on body, chest & arms.{" "}
                    <span style={{ color: '#1cc0d9', cursor: 'pointer' }} onClick={showModal}>
                      Fit and Sizing Guide
                    </span>
                  </p>
                </div>

                <Modal
                  title="Fit and Sizing Guide"
                  visible={isModalVisible}
                  onCancel={handleCancel}
                  footer={null}
                >
                  {/* Image inside the modal */}
                  <img
                    src={`https://backend.inkox.com/storage/${product.fitAndSizingGuide}`}
                    alt="Sizing Guide"
                    style={{ width: '100%' }}
                  />
                </Modal>




                <div className="btns-container" style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                  <Link href={"#"}>
                    <Title color={"skyblue6"} size={"18"} tag={"h6"} weight={500}>
                      Request a sample
                    </Title>
                  </Link>
                  |
                  <span
                    style={{ color: '#1cc0d9', cursor: 'pointer' }}
                    onClick={showQuote}
                  >
                    Get a Quote
                  </span>
                </div>

                <Modal
                  title="Calculate Your Price"
                  visible={isQuoteVisible}
                  onCancel={handleClose}
                  footer={null}
                  centered
                >
                  <p className="product-name">
                    Your Product: <strong>{product.name}</strong> in {selectedColor}
                  </p>
                  <hr />
                  <div className="quote-section">
                    <label>How many will you need?</label>
                    <InputNumber
                      min={1}
                      value={quantity}
                      onChange={(value) => setQuantity(value)}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <hr />
                  <div className="quote-section">
                    <p>How many ink colors are in your design?</p>
                    <Row gutter={16} justify="center" align="middle">
                      <Col span={12} className="quote-side">
                        <Button onClick={() => setFrontColor(frontColor - 1)} disabled={frontColor <= 0}>
                          -
                        </Button>
                        <InputNumber
                          min={0}
                          max={8}
                          value={frontColor}
                          onChange={(value) => setFrontColor(value)}
                          style={{ width: "60px" }}
                        />
                        <Button onClick={() => setFrontColor(frontColor + 1)} disabled={frontColor >= 8}>
                          +
                        </Button>
                        <p>FRONT SIDE</p>
                      </Col>

                      <Col span={12} className="quote-side">
                        <Button onClick={() => setBackColor(backColor - 1)} disabled={backColor <= 0}>
                          -
                        </Button>
                        <InputNumber
                          min={0}
                          max={8}
                          value={backColor}
                          onChange={(value) => setBackColor(value)}
                          style={{ width: "60px" }}
                        />
                        <Button onClick={() => setBackColor(backColor + 1)} disabled={backColor >= 8}>
                          +
                        </Button>
                        <p>BACK SIDE</p>
                      </Col>
                    </Row>
                  </div>
                  <hr />
                  <h1>{totalPrice}</h1>
                  <Button type="primary" style={{ width: "100%", marginTop: "20px" }}>
                    Get Quote
                  </Button>
                </Modal>

                {/* Added Section - Product Info and Discount */}
                <Row gutter={[20, 20]}>
                  <Col xs={12} md={12} lg={12} xl={6}>
                    <div className="product-info-card">
                      <img src={standardImg} alt="standardImg" />
                      <Title size={"18"} tag={"h6"} weight={600}>
                      {product.Fit || "N/A"}
                      </Title>
                      <Description>Fit</Description>
                    </div>
                  </Col>
                  <Col xs={12} md={12} lg={12} xl={6}>
                    <div className="product-info-card">
                      <img src={heavyWeightImg} alt="heavyWeightImg" />
                      <Title size={"18"} tag={"h6"} weight={600}>
                      {product.Weight || "N/A"} kg
                      </Title>
                      <Description>Weight</Description>
                    </div>
                  </Col>
                  <Col  xs={12} md={12} lg={12} xl={6}>
                    <div className="product-info-card">
                      <img src={materialImg} alt="materialImg" />
                      <Title size={"18"} tag={"h6"} weight={600}>
                      {product.material || "N/A"}
                      </Title>
                      <Description>Material</Description>
                    </div>
                  </Col>
                  <Col xs={12} md={12} lg={12} xl={6}>
                    <div className="product-info-card">
                      <img src={budgetImg} alt="budgetImg" />
                      <Title size={"18"} tag={"h6"} weight={600}>
                        Budget
                      </Title>
                      <Description>Price</Description>
                    </div>
                  </Col>
                </Row>

                {/* <div className="discount">
                  <img src={dollarIcon} alt="dollarIcon" />
                  <Title size={"18"} tag={"h6"} weight={500}>
                    Get $200 instant discount on your First Purchase above $999. Coupon code -{`<strong>NEW200</strong>`}
                  </Title>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="additional-information-container">
        <Container>
        <Row className="main-row-product-detail">
  <Col xs={24} md={8} className="bordermain-productpage">
    {/* Delivery Options Section */}
    <div className="delivery-options" style={{ marginBottom: "20px" }}>
      <Title
        size={"22"}
        tag={"h4"}
        weight={600}
        style={{ marginBottom: "10px" }}
        className="title-product-detail"
      >
        Delivery Options
      </Title>
      <ul className="delivery-list" style={{ listStyleType: "none", padding: 0 }}>
        <li
          className="fs-18"
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
            gap: "10px",
          }}
        >
          <img
            src={free}
            alt="Standard Preview Icon"
            style={{ width: "40px", height: "auto" }}
          />
          <div>
            <strong>Standard:</strong>
            <p style={{ margin: 0, fontSize: "14px", color: "#555" }}>
              Get your Order after <strong>14 working days</strong>. Date:{" "}
              {calculateDeliveryDate(14)}
            </p>
          </div>
        </li>
        <li
          className="fs-18"
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
            gap: "10px",
          }}
        >
          <img
            src={rush}
            alt="Rush Preview Icon"
            style={{ width: "40px", height: "auto" }}
          />
          <div>
            <strong>Rush:</strong>
            <p style={{ margin: 0, fontSize: "14px", color: "#555" }}>
              Get your Order after <strong>7 working days</strong>. Date:{" "}
              {calculateDeliveryDate(7)}
            </p>
          </div>
        </li>
        <li
          className="fs-18"
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
            gap: "10px",
          }}
        >
          <img
            src={email}
            alt="Super Rush Preview Icon"
            style={{ width: "40px", height: "auto" }}
          />
          <div>
            <strong>Super Rush:</strong>
            <p style={{ margin: 0, fontSize: "14px", color: "#555" }}>
              Get your Order after <strong>3 working days</strong>. Date:{" "}
              {calculateDeliveryDate(3)}
            </p>
          </div>
        </li>
      </ul>
    </div>
  </Col>
  <Col xs={24} md={8} className="bordermain-productpage">
  {/* Product Description Section */}
  <div className="description">
    <Title
      size={"22"}
      tag={"h4"}
      weight={600}
      style={{ marginBottom: "10px" }}
      className="title-product-detail"
    >
      Product Description
    </Title>
    {product?.description ? (
      parseDescription(product.description) // Render parsed description
    ) : (
      <p>No description available</p>
    )}
  </div>
</Col>





  <Col xs={24} md={8} className="bordermain-productpage">
    {/* Additional Information Section */}
    <Title
      size={"22"}
      tag={"h4"}
      weight={600}
      style={{ marginBottom: "10px" }}
      className="title-product-detail"
    >
      Additional Information
    </Title>
    <ul className="additional-information" style={{fontSize:"14px"}}>
      <li className="fs-18">
        <Description>WEIGHT</Description> {product.Weight || "N/A"} kg
      </li>
      <li className="fs-18">
        <Description>BRAND</Description> {product.brand || "N/A"}
      </li>
      <li className="fs-18">
        <Description>FABRIC</Description> {product.Fabric || "N/A"}
      </li>
    </ul>
  </Col>
</Row>

        </Container>
      </div>
    </div>
  );
};

export default ProductDetail;
