import React, { useEffect, useRef } from 'react';
import './changeproduct.css'; // Optional: Your custom styles for modal
import crossiconimg from '../assets/crossicon.png';

const ChangeProductModal = ({ isOpen, onClose }) => {
  const iframeRef = useRef(null);

  if (!isOpen) return null; // If the modal is not open, return null

  // Function to inject CSS into the iframe
  const injectCssIntoIframe = (iframe) => {
    if (!iframe) return;

    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

    if (iframeDoc) {
      // Create a <style> element and inject the CSS
      const style = document.createElement('style');
      style.innerHTML = `
        header.header {
          display: none;
        }
        footer.footer-main {
          display: none;
        }
        .faqs {
          display: none;
        }
      `;

      // Append the style to the iframe's head
      iframeDoc.head.appendChild(style);
    }
  };

  // Function to go back in the iframe's history
  const handleBackClick = (e) => {
    e.preventDefault(); // Prevent default button behavior
    e.stopPropagation(); // Stop event bubbling
    
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.history.back(); // Go back in iframe history
    }
  };
  
  

  return (
    <div className="modal-overlay">
      <div className="modal-background-color">
        <div className="modal-content">
          <div className='top-nav-popup-tool'>
            <h2> 
              
              {/* <button onClick={handleBackClick} className="back-buttonspopup">
                Back
              </button> */}
              
              Select a Product</h2>
           
            <button onClick={onClose} className="close-buttonspopup">
              <img src={crossiconimg} alt="Close Icon"/>
            </button>
          </div>

          {/* Load the Products component inside iframe */}
          <iframe
            ref={iframeRef}
            src="/products" // This is the route for Products component
            title="Product Preview"
            style={{
              width: '100%',
              height: '100%', // Adjust the height as needed
              border: '1px solid #ccc',
              marginTop: '0px',
            }}
            allowFullScreen
            onLoad={(e) => injectCssIntoIframe(e.target)} // Inject CSS after the iframe loads
          ></iframe>

          {/* Back and Close buttons */}
        </div>
      </div>
    </div>
  );
};

export default ChangeProductModal;
