import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import LayoutWrapper from "../layout/layoutWrapper/LayoutWrapper";
import "./orderplaced.css";

function OrderPlaced() {
  const location = useLocation();
  const navigate = useNavigate();
  const { orderId } = location.state || {}; // Get order ID from navigation state
  const [accountDetails, setAccountDetails] = useState({ username: "", password: "" }); // To store registered credentials
  const [isRegistered, setIsRegistered] = useState(false); // Tracks if the user is already registered

  // Function to handle "My Account" button click
  const handleMyAccountClick = () => {
    const appToken = localStorage.getItem("APP_TOKEN");
    if (appToken) {
      navigate("/accounts"); // Redirect to the Account page if the token exists
    } else {
      navigate("/login"); // Redirect to the Login page if the token doesn't exist
    }
  };

  // Automatically register the user if APP_TOKEN and Email_guest are available
  useEffect(() => {
    const emailGuest = Cookies.get("Email_guest");

    if (emailGuest) {
      // Prepare the signup data
      const requestBody = {
        name: "Guest User", // Default name
        email: emailGuest,
        pwd: emailGuest, // Use the email as the password
        total_design: 0,
        save_design: 0,
        total_order: 0,
        pending_order: 0,
      };

      // Call the signup API
      const signupUser = async () => {
        try {
          const response = await axios.post(
            "https://backend.inkox.com/api/user_login",
            requestBody
          );

          // Always set account details for preview
          setAccountDetails({ username: emailGuest, password: emailGuest });

          if (response.data.status === "success") {
            // Successfully registered
            setIsRegistered(false); // Ensure "already registered" message doesn't show
          } else if (response.data.message?.includes("already exists")) {
            // Email is already registered
            setIsRegistered(true);
          } else {
            console.error("Error during signup:", response.data.message);
          }
        } catch (error) {
          console.error("Error during signup:", error);
        }
      };

      signupUser();
    }
  }, []);

  return (
    <LayoutWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#f3f3f3",
          paddingTop: "70px",
          paddingBottom: "70px",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: "10px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            padding: "3rem 2rem",
            maxWidth: "50%",
            textAlign: "center",
          }}
        >
          <div
            style={{
              width: "100px",
              height: "100px",
              margin: "0 auto",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {orderId ? (
              // Success SVG Icon
              <svg
                width="800px"
                height="800px"
                viewBox="0 0 24 24"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                aria-labelledby="circleOkIconTitle"
                stroke="#555555"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                color="#000000"
              >
                <title id="circleOkIconTitle">OK</title>
                <polyline points="7 13 10 16 17 9" />
                <circle cx="12" cy="12" r="10" />
              </svg>
            ) : (
              // Error SVG Icon
              <svg
                width="800px"
                height="800px"
                viewBox="0 0 24 24"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                aria-labelledby="circleErrorIconTitle"
                stroke="#555555"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                color="#FF0000"
              >
                <title id="circleErrorIconTitle">Error</title>
                <circle cx="12" cy="12" r="10" />
                <line x1="8" y1="8" x2="16" y2="16" />
                <line x1="16" y1="8" x2="8" y2="16" />
              </svg>
            )}
          </div>

          {orderId ? (
            <>
              <h1 style={{ margin: "1rem 0", color: "#333", paddingBottom: "26px" }}>
                Thank you for your order!
              </h1>
              <p style={{ color: "#555", fontSize: "14px", lineHeight: "1.5" }}>
                Your order has been placed successfully!
                <div style={{ padding: "16px" }}>
                  <strong>Your Order ID is: {orderId}</strong>
                </div>
              </p>
              <p style={{ color: "#555", fontSize: "14px", lineHeight: "1.5" }}>
                You will shortly receive an email confirmation. You can check the status of your order at any time by
                going to{" "}
                <span
                  style={{ textDecoration: "underline", color: "#007bff", cursor: "pointer" }}
                  onClick={handleMyAccountClick}
                >
                  My Account
                </span>.
              </p>
            </>
          ) : (
            <>
              <h2 style={{ margin: "1rem 0", color: "#333" }}>Order Not Found</h2>
              <p style={{ color: "#555", fontSize: "14px", lineHeight: "1.5" }}>
                Please place your order to receive your order details. If you have already placed an order, check your
                email for confirmation or contact support.
              </p>
            </>
          )}

          {isRegistered ? (
            <div style={{ marginTop: "20px", color: "red" }}>
              <p>You are already registered. Please login to access your account.</p>
              <div style={{ marginTop: "10px" }}>
                <h3>Your Account Details:</h3>
                <p>
                  <strong>Username:</strong> {accountDetails.username}
                </p>
                <p>
                  <strong>Temporary Password:</strong> {accountDetails.password}
                </p>
              </div>
            </div>
          ) : (
            accountDetails.username && (
              <div style={{ marginTop: "20px" }} className="accountidpass">
                <h2>Account Details</h2>
                <p>
                  <strong>Username:</strong> {accountDetails.username}
                </p>
                <p>
                  <strong>Temporary Password:</strong> {accountDetails.password}
                </p>
              </div>
            )
          )}

          <div
            style={{
              marginTop: "1.5rem",
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <button
              className="trackyourorder"
              style={{
                padding: "10px 20px",
                backgroundColor: "rgb(28, 192, 217)",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: "bold",
              }}
              onClick={handleMyAccountClick}
            >
              My Account
            </button>
            <button
              style={{
                padding: "10px 20px",
                backgroundColor: "#6c757d",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: "bold",
              }}
              onClick={() => navigate("/products")}
            >
              Go to Shop
            </button>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
}

export default OrderPlaced;
