import React, { useEffect } from "react";
import Title from "../../components/typography/title/Title";
import chat from "../../assets/images/chat.png";
import headphone from "../../assets/images/head.png";

const ChatTalk = ({ size }) => {
  // Load Zoho SalesIQ Script
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://salesiq.zoho.com/widget"; // Replace with your SalesIQ Widget script link
    script.async = true;
    document.body.appendChild(script);
  }, []);

  // Function to Show Chat Window
  const openChat = () => {
    if (window.$zoho && window.$zoho.salesiq) {
      window.$zoho.salesiq.floatwindow.visible("show");
    } else {
      console.error("Zoho SalesIQ not loaded");
    }
  };

  return (
    <div className={`chatTalk ${size && size}`}>
      <div className="wayOfContact">
        <div className="box">
          <img src={headphone} alt="headphone" />
          <div className="text-box">
            <Title tag={"h6"} color={"red"} size={"16"} weight={600}>
              +1 0000 0000
            </Title>
          </div>
        </div>
        <div className="box" onClick={openChat}>
          <img src={chat} alt="chat" />
          <div className="text-box">
            <Title tag={"h6"} color={"red"} size={"16"} weight={600}>
              CHAT NOW
            </Title>
            {/* Button to Open Live Chat */}
            {/* <a href="#!">
              Live Chat
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatTalk;
