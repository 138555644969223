import { Col, Collapse, Input, Radio, Row, Select, Slider, Checkbox, Tag } from "antd";
import React, { useState } from "react";
import arrowDownIcon from "../../assets/images/arrow-down.png";
import sortIcon from "../../assets/images/sort-icon.png";
import SimpleCard from "../../components/simpleCard/SimpleCard";
import Title from "../../components/typography/title/Title";
import { CloseOutlined, FilterOutlined } from "@ant-design/icons";
import "./style.css";
import { colorHexMap } from "../../view/colorlibrarygarments";


const ProductsWithFilters = ({ data, handleColorClick,subCategoryName }) => {
  const { Search } = Input;
  const { Panel } = Collapse;


  

  // State for filters
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedPriceRange, setSelectedPriceRange] = useState([0, 1000000]);
  const [selectedFit, setSelectedFit] = useState(null);
  const [selectedWeight, setSelectedWeight] = useState(null);
  const [selectedSleeveLength, setSelectedSleeveLength] = useState(null);
  const [selectedAge, setSelectedAge] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // For search functionality
  const [sortOrder, setSortOrder] = useState(null); // For sorting functionality

  // State to control sidebar visibility
  const [sidebar, setSidebar] = useState(false);

  // Extract unique filter options based on the data
  const availableColors = [...new Set(data.flatMap((product) => product.colors || []))];
  const availableMaterials = [...new Set(data.map((product) => product.material))];
  const availableSizes = [...new Set(data.flatMap((product) => product.size.split(',').map((s) => s.trim())))];
  const availableBrands = [...new Set(data.map((product) => product.brand))];
  const availableFits = [...new Set(data.map((product) => product.fit))];
  const availableWeights = [...new Set(data.map((product) => product.weight))];
  const availableSleeveLengths = ["Short Sleeve", "Long Sleeve"];
  const availableAges = ["Adult", "Youth"];

  // Handle filter changes
  const onColorChange = (color) => {
    setSelectedColors((prevColors) =>
      prevColors.includes(color) ? prevColors.filter((c) => c !== color) : [...prevColors, color]
    );
  };

  const onMaterialChange = (e) => {
    setSelectedMaterial(e.target.value);
  };

  const onSizeChange = (size) => {
    setSelectedSize(size);
  };

  const onBrandChange = (brand) => {
    setSelectedBrand(brand);
  };

  const onPriceChange = (value) => {
    setSelectedPriceRange(value);
  };

  const onFitChange = (fit) => {
    setSelectedFit(fit);
  };

  const onWeightChange = (weight) => {
    setSelectedWeight(weight);
  };

  const onSleeveLengthChange = (length) => {
    setSelectedSleeveLength(length);
  };

  const onAgeChange = (age) => {
    setSelectedAge(age);
  };

  // Handle search term change
  const onSearch = (value) => {
    setSearchTerm(value.toLowerCase());
  };

  // Handle sorting by price
  const onSortChange = (value) => {
    setSortOrder(value);
  };

  // Remove filter and reset its state in the sidebar
  const removeFilter = (filterType) => {
    switch (filterType) {
      case 'color':
        setSelectedColors([]);
        break;
      case 'material':
        setSelectedMaterial(null);
        break;
      case 'size':
        setSelectedSize(null);
        break;
      case 'brand':
        setSelectedBrand(null);
        break;
      case 'fit':
        setSelectedFit(null);
        break;
      case 'weight':
        setSelectedWeight(null);
        break;
      case 'sleeveLength':
        setSelectedSleeveLength(null);
        break;
      case 'age':
        setSelectedAge(null);
        break;
      default:
        break;
    }
  };

  // Filter the products based on selected criteria and search term
  let filteredProducts = data.filter((product) => {
    const matchesColor = selectedColors.length === 0 || selectedColors.some((color) => product.colors.includes(color));
    const matchesMaterial = !selectedMaterial || product.material === selectedMaterial;
    const matchesSize = !selectedSize || product.size.split(',').map((s) => s.trim()).includes(selectedSize);
    const matchesBrand = !selectedBrand || product.brand === selectedBrand;
    const matchesPrice = product.price >= selectedPriceRange[0] && product.price <= selectedPriceRange[1];
    const matchesFit = !selectedFit || product.fit === selectedFit;
    const matchesWeight = !selectedWeight || product.weight === selectedWeight;
    const matchesSleeveLength = !selectedSleeveLength || product.sleeveLength === selectedSleeveLength;
    const matchesAge = !selectedAge || product.age === selectedAge;
    const matchesSearchTerm = product.title.toLowerCase().includes(searchTerm);

    return (
      matchesColor &&
      matchesMaterial &&
      matchesSize &&
      matchesBrand &&
      matchesPrice &&
      matchesFit &&
      matchesWeight &&
      matchesSleeveLength &&
      matchesAge &&
      matchesSearchTerm
    );
  });

  // Sort the products based on selected sort order (low to high or high to low)
  if (sortOrder === "low_to_high") {
    filteredProducts = filteredProducts.sort((a, b) => a.price - b.price);
  } else if (sortOrder === "high_to_low") {
    filteredProducts = filteredProducts.sort((a, b) => b.price - a.price);
  }


console.log("subCategoryName",subCategoryName);

  return (
    <div className="productsWithFilters">
      <Row className="page-header">
        <Col className="title-section" md={24}>
          <Title size={"41"} weight={700} tag={"h1"}>
             {subCategoryName}
          </Title>
          <Title customClass={"sub-title"} size={"21"} weight={500} tag={"h5"}>
            Design your  {subCategoryName} online with fast and free shipping
          </Title>
        </Col>
      </Row>

    

      <Row align={"middle"} className="header-row">
        <Col  md={5} className="d-desktop">
          <Title size={"26"} weight={400} tag={"h3"}>
            Available options
          </Title>
        </Col>
        <Col xs={24} md={24} lg={6} className="d-mobile">
          <button onClick={() => setSidebar(!sidebar)} className="filter-btn">
            <Title size={"26"} weight={400} tag={"h3"}>
              Filters
            </Title>
            <FilterOutlined />
          </button>
        </Col>
        <Col xs={24} md={24} lg={19}>
          <Row justify={"space-between"} align={"middle"}>
            <Col xs={24} md={24} lg={17}>
              <div className="search-container">
                <Title size={"20"} weight={400} tag={"h3"}>
                  {`Showing <span class="title fw-700"> ${filteredProducts.length} </span> products`}
                </Title>
                <Search className="custom-search" placeholder="Search" onSearch={onSearch} />
              </div>
            </Col>
            <Col xs={24} md={6}>
              <div className="sorting-container">
                <div className="image-container">
                  <img src={sortIcon} alt="sort-icon" className="sort-icon" />
                  <Title size={"20"} weight={400} tag={"h3"}>
                    Sort:
                  </Title>
                </div>
                <Select
                  suffixIcon={<img src={arrowDownIcon} alt="search-icon" />}
                  className="custom-select"
                  showSearch
                  placeholder="Sort by"
                  onChange={onSortChange}
                  options={[
                    { value: "popular", label: "Most Popular" },
                    { value: "low_to_high", label: "Price: Low to High" },
                    { value: "high_to_low", label: "Price: High to Low" },
                  ]}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="filter-content">
        <Col xs={24} md={5} className={`filter-sidebar ${sidebar ? "show" : ""}`}>
          <div className="d-mobile sidebar-close-btn">
            <CloseOutlined onClick={() => setSidebar(false)} />
          </div>
          <Collapse expandIconPosition="end" className="filters" accordion>
          {availableColors.length > 0 && (
  <Panel header="Color" key="1">
    <div className="colors-container filter-color">
      {availableColors.map((colorName, index) => {
        const colorHex = colorHexMap[colorName]?.hex || "#ffffff"; // Default to white if not found
        const isSelected = selectedColors.includes(colorName);
        return (
          <Checkbox
            key={index}
            checked={isSelected}
            onChange={() => onColorChange(colorName)}
            className="color-checkbox" // Optional class for styling if needed
          >
            <div 
              className={`color-box ${isSelected ? 'selected' : ''}`} 
              style={{ backgroundColor: colorHex }} 
            />
            {/* Remove color name if you want to preview only color */}
          </Checkbox>
        );
      })}
    </div>
  </Panel>
)}




            <Panel header="Price" key="2">
              <Slider range marks={{ 0: "$5", 100: "$490" }} value={selectedPriceRange} onChange={onPriceChange} />
            </Panel>

            {availableMaterials.length > 0 && (
              <Panel header="Material" key="3">
                <Radio.Group onChange={onMaterialChange} value={selectedMaterial}>
                  {availableMaterials.map((material, index) => (
                    <Radio key={index} value={material}>
                      {material}
                    </Radio>
                  ))}
                </Radio.Group>
              </Panel>
            )}

            {availableSizes.length > 0 && (
              <Panel header="Size" key="4">
                <Select value={selectedSize} onChange={onSizeChange} placeholder="Select Size">
                  {availableSizes.map((size, index) => (
                    <Select.Option key={index} value={size}>
                      {size}
                    </Select.Option>
                  ))}
                </Select>
              </Panel>
            )}

            {availableBrands.length > 0 && (
              <Panel header="Brand" key="5">
                <Select value={selectedBrand} onChange={onBrandChange} placeholder="Select Brand">
                  {availableBrands.map((brand, index) => (
                    <Select.Option key={index} value={brand}>
                      {brand}
                    </Select.Option>
                  ))}
                </Select>
              </Panel>
            )}

            {availableFits.length > 0 && (
              <Panel header="Fit" key="6">
                <Select value={selectedFit} onChange={onFitChange}>
                  {availableFits.map((fit, index) => (
                    <Select.Option key={index} value={fit}>
                      {fit}
                    </Select.Option>
                  ))}
                </Select>
              </Panel>
            )}

            {availableWeights.length > 0 && (
              <Panel header="Weight" key="7">
                <Select value={selectedWeight} onChange={onWeightChange} placeholder="Select Weight">
                  {availableWeights.map((weight, index) => (
                    <Select.Option key={index} value={weight}>
                      {weight}
                    </Select.Option>
                  ))}
                </Select>
              </Panel>
            )}

            {availableSleeveLengths.length > 0 && (
              <Panel header="Sleeve Length/Style" key="8">
                <Select value={selectedSleeveLength} onChange={onSleeveLengthChange} placeholder="Select Sleeve Length">
                  {availableSleeveLengths.map((length, index) => (
                    <Select.Option key={index} value={length}>
                      {length}
                    </Select.Option>
                  ))}
                </Select>
              </Panel>
            )}

            {availableAges.length > 0 && (
              <Panel header="Age" key="9">
                <Radio.Group onChange={onAgeChange} value={selectedAge}>
                  {availableAges.map((age, index) => (
                    <Radio key={index} value={age}>
                      {age}
                    </Radio>
                  ))}
                </Radio.Group>
              </Panel>
            )}
          </Collapse>
        </Col>

        <Col md={24} lg={19}>
          {/* Display Active Filters */}
      <Row className="active-filtersss">
        {selectedColors.length > 0 && (
          <Tag closable onClose={() => removeFilter('color')}>
            Colors: {selectedColors.join(", ")}
          </Tag>
        )}
        {selectedMaterial && (
          <Tag closable onClose={() => removeFilter('material')}>
            Material: {selectedMaterial}
          </Tag>
        )}
        {selectedSize && (
          <Tag closable onClose={() => removeFilter('size')}>
            Size: {selectedSize}
          </Tag>
        )}
        {selectedBrand && (
          <Tag closable onClose={() => removeFilter('brand')}>
            Brand: {selectedBrand}
          </Tag>
        )}
        {selectedFit && (
          <Tag closable onClose={() => removeFilter('fit')}>
            Fit: {selectedFit}
          </Tag>
        )}
        {selectedWeight && (
          <Tag closable onClose={() => removeFilter('weight')}>
            Weight: {selectedWeight}
          </Tag>
        )}
        {selectedSleeveLength && (
          <Tag closable onClose={() => removeFilter('sleeveLength')}>
            Sleeve Length: {selectedSleeveLength}
          </Tag>
        )}
        {selectedAge && (
          <Tag closable onClose={() => removeFilter('age')}>
            Age: {selectedAge}
          </Tag>
        )}
      </Row>
      <Row className="productListing" gutter={[20, 20]}>
  {filteredProducts.map((item, index) => (
    <Col xs={24} sm={12} md={12} lg={12} xl={8} key={index}>
      <SimpleCard cardType={"filterCard"} data={item}  colors={item.colors} handleColorClick={handleColorClick}/>
    </Col>
  ))}
</Row>

        </Col>
      </Row>
    </div>
  );
};

export default ProductsWithFilters;
