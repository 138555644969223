import React, { useState, useEffect } from 'react';
import "./workorderpage.css"
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import pantoneColors  from '../view/pantone-coated.json';
import { colorHexMap } from '../view/colorlibrarygarments'; // Adjust the path to match your file location



const WorkOrderPage = () => {
  const [workOrders, setWorkOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [hoveredColor, setHoveredColor] = useState(null); // State for hovered color


  useEffect(() => {
    const fetchWorkOrders = async () => {
      try {
        const response = await fetch('https://backend.inkox.com/api/place-orders');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setWorkOrders(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchWorkOrders();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const handleIdChange = (e) => {
    setSelectedId(Number(e.target.value));
  };
  const downloadPDF = () => {
    const content = document.getElementById('work-orders-container');
  
    if (!content) {
      console.error("Element with ID 'work-orders-container' not found");
      return;
    }
  
    // Configure html2canvas to handle cross-origin images
    html2canvas(content, {
      scale: 2, // Increase resolution for clarity
      useCORS: true, // Allow cross-origin images
      allowTaint: false, // Prevent issues with tainted canvases
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4'); // Set PDF to A4 size
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
  
      // Calculate dimensions to fit the content in the PDF
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      const aspectRatio = canvasHeight / canvasWidth;
  
      const imgWidth = pdfWidth; // Fit the width to PDF
      const imgHeight = pdfWidth * aspectRatio; // Scale height accordingly
  
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save('work_orders.pdf');
    }).catch((error) => {
      console.error("Error generating PDF:", error);
    });
  };



  const findClosestColor = (hex) => {
    const hexToRgb = (h) => h.match(/\w\w/g).map((x) => parseInt(x, 16));
    const colorDistance = (rgb1, rgb2) =>
      Math.sqrt(rgb1.reduce((sum, val, i) => sum + Math.pow(val - rgb2[i], 2), 0));

    const targetRgb = hexToRgb(hex);
    let closestColor = { pantone: 'unknown', hex: '#FFFFFF' };
    let smallestDistance = Infinity;

    pantoneColors.forEach((color) => {
      const pantoneRgb = hexToRgb(color.hex);
      const distance = colorDistance(targetRgb, pantoneRgb);
      if (distance < smallestDistance) {
        closestColor = color;
        smallestDistance = distance;
      }
    });
    return closestColor;
  };

  const getColorInfo = (color) => {
    if (!color) return { pantone: 'unknown', hex: '#FFFFFF' };

    let hex = color.startsWith("rgb") ? rgbToHex(color) : color;

    if (!hex) return { pantone: 'unknown', hex: '#FFFFFF' };

    const exactMatch = pantoneColors.find((c) => c.hex.toLowerCase() === hex.toLowerCase());
    if (exactMatch) return exactMatch;

    return findClosestColor(hex);
  };

  const rgbToHex = (rgb) => {
    const result = rgb.match(/\d+/g);
    if (result && result.length >= 3) {
      const [r, g, b] = result.map(Number);
      return (
        "#" +
        [r, g, b]
          .map((x) => x.toString(16).padStart(2, "0"))
          .join("")
      );
    }
    return null;
  };

  const mergeAllColors = (inkColors) => {
    const mergedColors = new Set();
    Object.values(inkColors || {}).forEach((colors) => {
      if (Array.isArray(colors)) {
        colors.forEach((color) => mergedColors.add(color));
      }
    });
    return Array.from(mergedColors);
  };
  

  
  const filteredWorkOrders = selectedId
    ? workOrders.filter((order) => order.id === selectedId)
    : workOrders;

  return (
    <div  id="work-orders-container" className="work-orders-container" style={{  padding: '20px' }}>
      <h1 style={{ textAlign: 'center', color: '#d32f2f' }}>Fully Promoted Work Orders</h1>
      <div className="selector" style={{ marginBottom: '20px', textAlign: 'center' }}>
        <label htmlFor="id-selector" style={{ fontSize: '16px', marginRight: '10px' }}>Select Order ID: </label>
        <select id="id-selector" onChange={handleIdChange} style={{ padding: '5px', fontSize: '16px' }}>
          <option value="">All</option>
          {workOrders.map((order) => (
            <option key={order.id} value={order.id}>{order.id}</option>
          ))}
        </select>
      </div>

      
      {filteredWorkOrders.map((order) => {
        const mergedColors = mergeAllColors(order.cartData[0]?.inkColors);
        return (
          <>
        <div key={order.id} className="work-order" style={{ border: '1px solid #ccc', padding: '20px', marginBottom: '20px', borderRadius: '5px' }}>
          <h2 style={{ textAlign: 'center', color: '#333' }}>Order ID: {order.id}</h2>

<table style={{width:'100%',position:'relative',}}>

          <tr className='main-color-image'>
          <td style={{ textAlign: '',width:'70%' }}>
          {order.cartData.map((item) => (
                <div key={item.id}>
                  <h2 className='product-name-work'>{item.productName}</h2>
                 
                
                </div>
              ))}
        
            {/* <h3>Images</h3> */}
        
           

            {order.cartData[0]?.imagePreviews?.map((preview, index) => (
             <td className='image-workorder'> <img
                key={index}
                src={preview.preview}
                alt={`${preview.part} preview`}
                style={{ width: '100%', marginRight: '10px' }}
              />
              </td>
            ))}
          </td>
          <td style={{ textAlign: 'center', marginBottom: '20px' }}>
  <h3>Ink Colors</h3>
  <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '10px' }}>
    {/* {['Front', 'Back', 'Left', 'Right'].map((part) => (
      <div key={part} style={{ textAlign: 'center' }} className="colorbox-workorder">
        <h6 style={{ marginBottom: '5px' }}>{part}</h6>
        {(order.cartData[0]?.inkColors?.[part] || []).map((color, index) => {
          const colorInfo = getColorInfo(color);
          return (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                marginBottom: '10px',
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  width: '25px',
                  height: '25px',
                  backgroundColor: color, // Use color from Pantone or fallback
                  border: '1px solid #000',
                }}
                onMouseEnter={() => setHoveredColor(colorInfo.hex)} // Set hovered color
                onMouseLeave={() => setHoveredColor(null)} // Reset hovered color
              ></div>
              <div>
                <div>{color}</div>
                <div>{colorInfo.name} ({colorInfo.id})</div>
              </div>
            </div>
          );
        })}
      </div>
    ))} */}

    <div style={{ border: '1px solid #ddd', width: '100%', padding: '8px', textAlign: 'center' }}>
      {/* <h3>All Colors</h3> */}
      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px', textAlign: 'center' }}>
  <thead>
    <tr>
      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Color Preview</th>
      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Hex Code</th>
      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Suggested Pantone</th>
    </tr>
  </thead>
  <tbody>
    {mergedColors.map((color, index) => {
      const colorInfo = getColorInfo(color); // Get color info including hex
      return (
        <tr key={index}>
          <td style={{ border: '1px solid #ddd', padding: '8px' }}>
            <div
              style={{
                display: 'inline-block',
                width: '25px',
                height: '25px',
                backgroundColor: color || '#FFFFFF', // Fallback to white if hex is missing
                border: '1px solid #000',
              }}
              onMouseEnter={() => setHoveredColor(colorInfo.hex)} // Set hovered color
              onMouseLeave={() => setHoveredColor(null)} // Reset hovered color
            ></div>
          </td>
          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{color}</td>
          <td style={{ border: '1px solid #ddd', padding: '8px' }}>
            {colorInfo.pantone} 
          </td>
        </tr>
      );
    })}
  </tbody>
</table>

    </div>
  </div>
</td>

<td style={{position:'absolute',left:'0px',top:'0px',}} className='hover-colorss'>  {hoveredColor && (
    <div style={{ textAlign: 'center', marginTop: '20px', }}>
      <div
        style={{
          display: 'inline-block',
          width: '100px',
          height: '100px',
          backgroundColor: hoveredColor,
          border: '2px solid #000',
          marginBottom: '8px',
        }}
      ></div>
      <div style={{ fontSize: '16px' }}>{hoveredColor}</div>
    </div>
  )}</td>
          
        
          </tr>
          </table>
          
          <div style={{ display: 'flex', justifyContent: 'space-around', gap: '20px', flexWrap: 'wrap', marginBottom: '20px',marginTop: '20px' }}>
  {/* Uploaded Images Section */}
  <div style={{ textAlign: 'center' }}>
    <h3>Uploaded Images</h3>
    {order.cartData[0]?.uploadedImageData.length > 0 ? (
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center' }}>
        {order.cartData[0].uploadedImageData.map((uploaded, index) => (
          <img
            key={index}
            src={uploaded.preview}
            alt={`Uploaded ${uploaded.part}`}
            style={{
              width: '50px',
              height: '50px',
              objectFit: 'contain',
              border: '1px solid #ccc',
              borderRadius: '5px',
            }}
          />
        ))}
      </div>
    ) : (
      <p>No uploaded images</p>
    )}
  </div>

  {/* Clip Art Data Section */}
  <div style={{ textAlign: 'center' }}>
    <h3>Clip Art Data</h3>
    {order.cartData[0]?.clipArtData.length > 0 ? (
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center' }}>
        {order.cartData[0].clipArtData.map((clip, index) => (
          <div key={index} style={{ textAlign: 'center', maxWidth: '150px' }}>
            <img
              src={clip.clipartPath}
              alt={`Clip Art on ${clip.part}`}
              style={{
                width: '50px',
                height: '50px',
                objectFit: 'contain',
                border: '1px solid #ccc',
                borderRadius: '5px',
              }}
            />
            <p>
              <strong>Part:</strong> {clip.part}
            </p>
          </div>
        ))}
      </div>
    ) : (
      <p>No clip art data</p>
    )}
  </div>
</div>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
  <table style={{ width: '48%', borderCollapse: 'collapse', marginBottom: '20px' }}>
    <tbody>
      <tr>
        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 'bold' }}>Created At</td>
        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
          {order.cartData[0]?.created_at?.split('T')[0] || 'Not Available'}
        </td>
      </tr>
      <tr>
        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 'bold' }}>Printing Type</td>
        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
          {order.cartData[0]?.orderType || 'Not Available'}
        </td>
      </tr>
      <tr>
        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 'bold' }}>Order ID</td>
        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>{order.id}</td>
      </tr>
    </tbody>
  </table>

  <table style={{ width: '48%', borderCollapse: 'collapse', marginBottom: '20px' }}>
    <tbody>
      <tr>
        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 'bold' }}>Order Status</td>
        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>{order.orderStatus}</td>
      </tr>
      <tr>
        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 'bold' }}>Payment Status</td>
        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>{order.payment_status}</td>
      </tr>
      <tr>
        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 'bold' }}>Payment Amount</td>
        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
          {order.payment_amount} {order.payment_currency.toUpperCase()}
        </td>
      </tr>
      <tr>
        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 'bold' }}>Canvas ID</td>
        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>
          {order.cartData[0]?.canvasId || 'Not Available'}
        </td>
      </tr>
    </tbody>
  </table>
</div>


          <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px',  }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>Garments</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>Style</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>Color</th>
                {['S', 'M', 'L', 'XL', '2XL', '3XL'].map((size) => (
                  <th key={size} style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{size}</th>
                ))}
                <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>Total</th>
              </tr>
            </thead>
            <tbody>
  {order.cartData.map((item) => {
    // Match the color in colorHexMap
    const matchedColor = Object.entries(colorHexMap).find(
      ([name, details]) => details.hex.toLowerCase() === item.productColor.toLowerCase()
    );

    // If matched, extract the name and ID; otherwise, provide default values
    const colorName = matchedColor ? matchedColor[0] : "Unknown";
    const colorId = matchedColor ? matchedColor[1].id : "N/A";

    return (
      <tr key={item.id}>
        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>Jackets</td>
        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{item.productName}</td>
        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>
          <>
            <span
              style={{
                display: 'inline-block',
                width: '25px',
                height: '25px',
                backgroundColor: item.productColor,
                border: '1px solid #000',
              }}
              onMouseEnter={() => setHoveredColor(item.productColor)} // Set hovered color
              onMouseLeave={() => setHoveredColor(null)} // Reset hovered color
            ></span>
            <div>{colorName}</div>
            <div>ID: {colorId}</div>
          </>
        </td>
        {['S', 'M', 'L', 'XL', '2XL', '3XL'].map((size) => (
          <td key={size} style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>
            {item.quantities[size] || 0}
          </td>
        ))}
        <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{item.totalQuantity}</td>
      </tr>
    );
  })}
</tbody>

          </table>
          <div className="special-instructions" style={{ marginTop: '20px', padding: '10px', backgroundColor: '#f5f5f5', borderRadius: '5px' }}>
            <h5>Special Instructions:</h5>
            <p>Please proofread all the details mentioned.</p>
          </div>
          <div className="desired-shipping-date" style={{ marginTop: '20px', textAlign: 'center', fontSize: '18px', fontWeight: 'bold', color: '#333' }}>
          Desired Shipping Date: {Object.values(order.cartData[0]?.Delivery_date || {}).join(', ') || 'Not Available'}
          </div>
        </div>
        </>
        );
      
      })}
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <button onClick={downloadPDF} style={{ padding: '10px 20px', fontSize: '16px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
          Download PDF
        </button>
      </div>
    </div>
  );
};

export default WorkOrderPage;