import React, { useEffect, useState } from "react";

const ObjectColorComponent = ({
  canvas,
  selectedObject,
  selectedObjectColors,
  setSelectedObjectColors,
  selectedColor,
  setSelectedColor,
  showColorPalette,
  setShowColorPalette,
  inkColors,
  setInkColors,
  onColorChange,
  handleFillChange,
  addToHistory,
}) => {
  const [hoveredColorName, setHoveredColorName] = useState(""); // State for hovered color name
  const [selectedColorName, setSelectedColorName] = useState(""); // State for selected color name
  const [colorData, setColorData] = useState([]); // Store color data for name lookup

  useEffect(() => {
    fetch("https://backend.inkox.com/api/inkcolors")
      .then((response) => response.json())
      .then((data) => {
        const allColors = data.map((item) =>
          item.color_hex.split(",").map((color) => color.trim())
        ).flat();
        setInkColors(allColors);
        setColorData(data); // Save data for color name lookup
      })
      .catch((error) => {
        console.error("Error fetching outline colors:", error);
      });
  }, [setInkColors]);

  useEffect(() => {
    // Update selected color name when the selectedColor changes
    if (selectedColor) {
      const colorName = getColorNameFromHex(selectedColor);
      setSelectedColorName(colorName);
    }
  }, [selectedColor]);

  const getColorNameFromHex = (hex) => {
    for (const item of colorData) {
      const colorHexArray = item.color_hex.split(",").map((color) => color.trim());
      const colorNameArray = item.color_name.split(",").map((name) => name.trim());
      const index = colorHexArray.indexOf(hex);
      if (index !== -1) {
        return colorNameArray[index] || "";
      }
    }
    return "";
  };

  const applyColorToClipart = (color) => {
    const activeObject = selectedObject;
    if (activeObject) {
      const prevState = activeObject.toObject([
        "left",
        "top",
        "scaleX",
        "scaleY",
        "src",
        "fill",
      ]);
      if (activeObject.type === "group") {
        activeObject.getObjects().forEach((obj) => {
          if (
            (obj.type === "path" ||
              obj.type === "rect" ||
              obj.type === "circle") &&
            obj.fill === selectedColor
          ) {
            obj.set("fill", color);
          }
        });
      } else if (
        activeObject.type === "path" &&
        activeObject.fill === selectedColor
      ) {
        activeObject.set("fill", color);
      } else if (activeObject.type === "text" || activeObject.type === "i-text") {
        activeObject.set("fill", color);
      }
      canvas.renderAll();
      const newState = activeObject.toObject([
        "left",
        "top",
        "scaleX",
        "scaleY",
        "src",
        "fill",
      ]);
      onColorChange();
      addToHistory(activeObject, prevState, newState, "colorChange");
    }
    setSelectedObjectColors((prevColors) =>
      prevColors.map((c) => (c === selectedColor ? color : c))
    );
    setShowColorPalette(false); // Close the palette after selecting a color
    handleFillChange(color);

    const colorName = getColorNameFromHex(color);
    setSelectedColorName(colorName); // Update the selected color name
    setSelectedColor(color);
  };

  const handleColorBoxClick = (color) => {
    setSelectedColor(color);
    const colorName = getColorNameFromHex(color);
    setSelectedColorName(colorName); // Update the selected color name
    setShowColorPalette(true);
  };

  const handleHoverColor = (color) => {
    const colorName = getColorNameFromHex(color);
    setHoveredColorName(colorName); // Update the hovered color name
  };

  const handleHoverOut = () => {
    setHoveredColorName(""); // Reset the hovered color name
  };

  return (
    <div style={{ width: "100%" }}>
      {showColorPalette ? (
        <>
         <div className="color-preview inner" style={{ marginTop: "10px" }}>
            <b style={{  paddingLeft: "10px" }}>Selected Color: {hoveredColorName || selectedColorName}</b>
          </div>
          <div className="color-palette" style={{ display: "flex", marginTop: "10px" }}>
            {inkColors.map((color, index) => (
              <div
                key={index}
                className={`color-box ${
                  selectedColor === color ? "selected" : "" // Highlight the selected color
                }`}
                style={{
                  backgroundColor: color,
                  border: selectedColor === color ? "2px solid blue" : "1px solid #000",
                  marginRight: "5px",
                  width: "25px",
                  height: "25px",
                }}
                onClick={() => applyColorToClipart(color)}
                onMouseEnter={() => handleHoverColor(color)}
                onMouseLeave={handleHoverOut}
              ></div>
            ))}
          </div>
          {/* Display the hovered or selected color name */}
         
        </>
      ) : (
        <>
          {Array.isArray(selectedObjectColors) && selectedObjectColors.length > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <label className="labFont" style={{ color: "black", fontSize: "12px" }}>
                Color
              </label>
              <div className="colors-gallery outline-main" style={{ display: "flex" }}>
                {selectedObjectColors.map((color, index) => (
                  <div
                    key={index}
                    className={`color-box ${
                      selectedColor === color ? "selected" : "" // Highlight the selected color
                    }`}
                    style={{
                      backgroundColor: color,
                      border: "none",
                      marginRight: "5px",
                      width: "25px",
                      height: "25px",
                      borderRadius: "5px",
                    }}
                    onClick={() => handleColorBoxClick(color)}
                    onMouseEnter={() => handleHoverColor(color)}
                    onMouseLeave={handleHoverOut}
                  ></div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ObjectColorComponent;
