import React from "react";
import Title from "../../components/typography/title/Title";
import SimpleButton from "../../components/simpleButton/SimpleButton";
import Container from "../../components/container/Container";
import { Col, Row } from "antd";
import blueLineImage from "../../assets/images/bluelines.png";
import imagescreen from "../../assets/images/image-screen.webp";

import CountdownTimer from "../../components/countdownTimer/CountdownTimer";

const OfferBanner = (props) => {
  const { data } = props;

  const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();

  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;

  return (
 <div className="main-orrderbanner">
      <Container>
        <div className="inner-offerbanner">
             <Title customClass={"text-center"} size={"41"} tag={"h2"} weight={700}>
             How To Design Your Own Shirt

        </Title>
        <Title customClass={"sub-title"} size={"21"} tag={"h2"} weight={500}>
        Dive into the collections that keep our customers coming back for more  </Title>
        </div>
        <Row gutter={[32, 32]} align="middle">
          {/* Left Column: Text Section */}
          
          <Col md={12} sm={24}>
  <div style={{ marginTop: "20px" }}>
 
    <ul style={{ listStyle: "none", padding: 0, marginTop: "20px" }}>
      <li style={{ marginBottom: "40px", display: "flex", alignItems: "flex-start" }}>
        <div
          style={{
            fontSize: "24px",
            color: "#fff",
            fontWeight: "700",
            marginRight: "15px",
            background: "#1cc0d9",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          1
        </div>
        <div className="innerheading-offerbanner">
          <Title color={"#000"} size={"20"} tag={"h4"} weight={700}>
            Select your T-Shirt
          </Title>
          <p style={{ margin: 0 }}>
            Select your choice of T-shirt from over 100 styles and colors.
          </p>
        </div>
      </li>
      <li style={{ marginBottom: "40px", display: "flex", alignItems: "flex-start" }}>
        <div
          style={{
            fontSize: "24px",
            color: "#fff",
            fontWeight: "700",
            marginRight: "15px",
            background: "#1cc0d9",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          2
        </div>
        <div className="innerheading-offerbanner">
          <Title color={"#000"} size={"20"} tag={"h4"} weight={700}>
            Design Your T-Shirt
          </Title>
          <p style={{ margin: 0 }}>
            Add text & design to truly personalize your T-Shirt.
          </p>
        </div>
      </li>
      <li style={{ marginBottom: "40px", display: "flex", alignItems: "flex-start" }}>
        <div
          style={{
            fontSize: "24px",
            color: "#fff",
            fontWeight: "700",
            marginRight: "15px",
            background: "#1cc0d9",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          3
        </div>
        <div className="innerheading-offerbanner">
          <Title color={"#000"} size={"20"} tag={"h4"} weight={700}>
            Get Your Order
          </Title>
          <p style={{ margin: 0 }}>Add to the basket, and you’re good to go!</p>
        </div>
      </li>
    </ul>
    <SimpleButton
      to={data.link}
      size={"medium"}
      bgColor={"#007bff"}
      color={"#fff"}
      customClass="shop-button"
      style={{ marginTop: "20px" }}
    >
        Start Designing

     
    </SimpleButton>
  </div>
</Col>


          {/* Right Column: Image Section */}
          <Col md={12} sm={24}>
            <div style={{ textAlign: "center" }}>
              <img
                src={imagescreen} // Replace with the actual image path
                alt="T-Shirt Design Preview"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: "10px",
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
      </div>
  
  );
};

export default OfferBanner;
