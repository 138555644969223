import { Col, Form, Input, Row, message } from "antd";
import React from "react";
import logo from "../../../assets/images/logo.png";
import bgImage from "../../../assets/images/offer-bnr.png";
import Container from "../../container/Container";
import SimpleButton from "../../simpleButton/SimpleButton";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import axios from 'axios';  // Use axios for API calls

const SignUpForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    console.log("Success:", values);

    // Create the body to send only email and pwd, set other fields to null
    const requestBody = {
      name: values.name,
      email: values.email,
      pwd: values.password,
      total_design: 0,
      save_design: 0,
      total_order: 0,
      pending_order: 0,
    };

    try {
      // Post the form data to the API
      const response = await axios.post("https://backend.inkox.com/api/user_login", requestBody);

      // Handle response (assuming API returns a `status` and `message`)
      const { status, message: apiMessage } = response.data;

      if (apiMessage) {
        message.success(apiMessage);
        navigate("/login");  // Navigate to login page on success
        form.resetFields();  // Clear the form fields
      } else {
        message.error(apiMessage || "Sign up failed");
      }

      console.log(response.data);
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error("Error during sign up:", error);
      message.error("An error occurred during sign-up. Please try again.");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="signUpForm">
      <div className="left-content">
        <Container>
          <img src={logo} alt="logo" className="logo" />
          <Row justify={"center"}>
            <Col md={16}>
              <Form
                form={form}
                layout="vertical"
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    {
                    
                      message: "Please input your Name!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Link className="login-account-btn" to={"/login"}>
                  Login account
                </Link>
                <SimpleButton
                  customClass={"fw-700"}
                  size={"small"}
                  bgColor={"yellow"}
                  color={"black"}
                  htmlType="submit"  // Ensure this submits the form
                >
                  Signup
                </SimpleButton>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="right-content">
        <img src={bgImage} alt="bg-image" />
      </div>
    </div>
  );
};

export default SignUpForm;
