import React, { useEffect, useRef } from 'react';
import { fabric } from 'fabric';

const DesignAreaComponentOne = ({ canvas,switchBar, isPriceSidebarVisible, controlSettings, updateSelectedObjectSize, onObjectSelected, productImage, setDesignArea, addText, selectedPart }) => {
  const designAreaRef = useRef(null);
  const designAreaTextRef = useRef(null);
  const centerLineRef = useRef(null);
  const guidelineBoxesRef = useRef([]);
  const guidelineBoxesTextRef = useRef([]);
  const rafRef = useRef(null);

  console.log("SelctedPart :", isPriceSidebarVisible);
  console.log("ProductImage :", productImage);
  const calculateProductDimensions = () => {
    if (!productImage) return {};
    const productWidth = productImage.width * productImage.scaleX;
    const productHeight = productImage.height * productImage.scaleY;
    const productLeft = productImage.left - productWidth / 2;
    const productTop = productImage.top - productHeight / 2;
    return { productWidth, productHeight, productLeft, productTop };
  };

  const createOrUpdateObject = (ref, properties, type = 'Rect') => {
    if (ref.current) {
      ref.current.set(properties);
      ref.current.setCoords();
    } else {
      const newObject = type === 'Rect'
        ? new fabric.Rect(properties)
        : new fabric.Text(properties.text || '', properties);
      canvas.add(newObject);
      ref.current = newObject;
    }
  };

  const createOrUpdateArrayObject = (refArray, index, properties, type = 'Rect') => {
    if (!refArray.current) refArray.current = [];
    if (refArray.current[index]) {
      refArray.current[index].set(properties);
      refArray.current[index].setCoords();
    } else {
      const newObject = type === 'Rect'
        ? new fabric.Rect(properties)
        : new fabric.Text(properties.text || '', properties);
      canvas.add(newObject);
      refArray.current[index] = newObject;
    }
  };

  const updateDesignAreaAndGuidelines = () => {
    if (!canvas || !productImage || !selectedPart) return;
  
    // Check product dimensions
    const { productWidth, productHeight, productLeft, productTop } = calculateProductDimensions();
  
    // Parse percentage values safely using parseFloat
    const leftPercent = parseInt(selectedPart.designableArea.leftPercent) || 0;
    const topPercent = parseInt(selectedPart.designableArea.topPercent) || 0;
    const widthPercent = parseInt(selectedPart.designableArea.widthPercent) || 0;
    const heightPercent = parseInt(selectedPart.designableArea.heightPercent) || 0;
  
    // Calculate design area properties
    const designAreaProps = {
      left: productLeft + ((leftPercent / 100) * productWidth),
      top: productTop + ((topPercent / 100) * productHeight),
      width: (widthPercent / 100) * productWidth,
      height: (heightPercent / 100) * productHeight,
      fill: 'transparent',
      stroke: 'rgba(150, 150, 150, 1)',
      strokeWidth: 1,
      selectable: false,
      evented: false,
      visible: isPriceSidebarVisible,
      designArea: true,
    };
  
    createOrUpdateObject(designAreaRef, designAreaProps);
  
    setDesignArea(designAreaRef.current);
  
    createOrUpdateObject(
      designAreaTextRef,
      {
        ...designAreaProps,
        left: designAreaProps.left + 3,
        top: designAreaProps.top + 3,
        text: 'Design Area',
        fontSize: 12,
        fill: 'rgba(150, 150, 150, 1)',
        visible: false,
      },
      'Text'
    );
  
    if (!centerLineRef.current) {
      centerLineRef.current = new fabric.Line(
        [
          designAreaProps.left + designAreaProps.width / 2,
          designAreaProps.top,
          designAreaProps.left + designAreaProps.width / 2,
          designAreaProps.top + designAreaProps.height,
        ],
        {
          stroke: 'rgba(150, 150, 150, 1)',
          strokeWidth: 1,
          strokeDashArray: [2, 1],
          selectable: false,
          evented: false,
          visible: false,
        }
      );
      canvas.add(centerLineRef.current);
    } else {
      centerLineRef.current.set({
        x1: designAreaProps.left + designAreaProps.width / 2,
        y1: designAreaProps.top,
        x2: designAreaProps.left + designAreaProps.width / 2,
        y2: designAreaProps.top + designAreaProps.height,
        visible: false,
      });
      centerLineRef.current.setCoords();
    }
  
    if (switchBar === 'screenPrinting') {
      selectedPart.guideboxes.forEach((box, index) => {
        const boxProps = {
          left: productLeft + (box.leftPercent / 100) * productWidth,
          top: productTop + (box.topPercent / 100) * productHeight,
          width: (box.widthPercent / 100) * productWidth,
          height: (box.heightPercent / 100) * productHeight,
          fill: 'transparent',
          stroke: 'rgba(150, 150, 150, 1)',
          strokeWidth: 1,
          selectable: false,
          evented: false,
          visible: false,
          shouldBeVisible: false,
        };
        createOrUpdateArrayObject(guidelineBoxesRef, index, boxProps);
        createOrUpdateArrayObject(
          guidelineBoxesTextRef,
          index,
          {
            ...boxProps,
            left: boxProps.left + 3,
            top: boxProps.top + 3,
            text: box.guidelineBoxText || '',
            fontSize: 12,
            fill: 'rgba(150, 150, 150, 1)',
            visible: false,
          },
          'Text'
        );
      });
    }
  
    canvas.renderAll();
  };
  
  const addCanvasEventListeners = () => {
    const showOutline = (e) => {
      designAreaRef.current.set('visible', true);
      designAreaTextRef.current.set('visible', true);
      centerLineRef.current.set('visible', true);
      if (switchBar === 'screenPrinting') {
        guidelineBoxesRef.current.forEach((box) => box.set('visible', true));
        guidelineBoxesTextRef.current.forEach((text) => text.set('visible', true));
      }
      e.target.set({ borderColor: 'transparent', cornerColor: 'transparent', cornerStrokeColor: 'transparent' });
      if (!rafRef.current) {
        rafRef.current = requestAnimationFrame(() => {
          canvas.renderAll();
          rafRef.current = null;
        });
      }
    };
    const hideOutline = (e) => {
      designAreaRef.current.set('visible', false);
      designAreaTextRef.current.set('visible', false);
      centerLineRef.current.set('visible', false);
      guidelineBoxesRef.current.forEach((box) => box.set('visible', false));
      guidelineBoxesTextRef.current.forEach((text) => text.set('visible', false));
      if (e.target) {
        e.target.set({ borderColor: controlSettings.borderColor, cornerColor: controlSettings.cornerColor, cornerStrokeColor: controlSettings.cornerStrokeColor });
        if (!rafRef.current) {
          rafRef.current = requestAnimationFrame(() => {
            canvas.renderAll();
            rafRef.current = null;
          });
        }
      }
    };

    const snapToCenter = (e) => {
      const obj = e.target;
      const objectCenterX = obj.left + obj.width * obj.scaleX / 2;
      const designAreaCenterX = designAreaRef.current.left + designAreaRef.current.width / 2;

      if (Math.abs(objectCenterX - designAreaCenterX) < 10 && obj.left >= designAreaRef.current.left && obj.left + obj.width * obj.scaleX <= designAreaRef.current.left + designAreaRef.current.width) {
        obj.left = designAreaCenterX - obj.width * obj.scaleX / 2;
        centerLineRef.current.set('visible', true);
      } else {
        centerLineRef.current.set('visible', false);
      }
      if (!rafRef.current) {
        rafRef.current = requestAnimationFrame(() => {
          canvas.renderAll();
          rafRef.current = null;
        });
      }
    };

    const updateObjectSize = (obj) => {
      if (obj) {
        const designAreaWidthInches = 13;
        const designAreaHeightInches = 17;
        const widthInches = (obj.width * obj.scaleX) * (designAreaWidthInches / designAreaRef.current.width);
        const heightInches = (obj.height * obj.scaleY) * (designAreaHeightInches / designAreaRef.current.height);
        updateSelectedObjectSize({ width: widthInches.toFixed(2), height: heightInches.toFixed(2) });
        onObjectSelected(obj);
      }
    };

    const clearObjectSize = () => {
      updateSelectedObjectSize({ width: 0, height: 0 });
      onObjectSelected(null);
    };

    canvas.on('object:moving', showOutline);
    canvas.on('object:scaling', showOutline);
    canvas.on('mouse:up', hideOutline);
    canvas.on('object:moving', snapToCenter);
    canvas.on('object:scaling', snapToCenter);
    canvas.on('mouse:down', (e) => { if (e.target) updateObjectSize(e.target); else clearObjectSize(); });
    canvas.on('selection:created', (e) => { if (e.selected) updateObjectSize(e.selected[0]); });
    canvas.on('selection:updated', (e) => { if (e.selected) updateObjectSize(e.selected[0]); });
    canvas.on('selection:cleared', clearObjectSize);
    canvas.on('object:modified', (e) => updateObjectSize(e.target));

    return () => {
      canvas.off('object:moving', showOutline);
      canvas.off('object:scaling', showOutline);
      canvas.off('mouse:up', hideOutline);
      canvas.off('object:moving', snapToCenter);
      canvas.off('object:scaling', snapToCenter);
      canvas.off('mouse:down');
      canvas.off('object:modified');
    };
  };

  useEffect(() => {
    if (canvas && productImage) {
      updateDesignAreaAndGuidelines();

      const handleResize = () => {
        updateDesignAreaAndGuidelines();
      };

      window.addEventListener('resize', handleResize);

      productImage.on('modified', updateDesignAreaAndGuidelines);
      productImage.on('scaling', updateDesignAreaAndGuidelines);
      productImage.on('moving', updateDesignAreaAndGuidelines);

      const removeCanvasEventListeners = addCanvasEventListeners();

      return () => {
        window.removeEventListener('resize', handleResize);

        productImage.off('modified', updateDesignAreaAndGuidelines);
        productImage.off('scaling', updateDesignAreaAndGuidelines);
        productImage.off('moving', updateDesignAreaAndGuidelines);

        removeCanvasEventListeners();
      };
    }
  }, [canvas, productImage, selectedPart]);

  return null;
};

export default DesignAreaComponentOne;
